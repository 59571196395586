import { Fragment, useEffect, useRef, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon, CalendarIcon } from '@heroicons/react/outline'

export const months = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec', 
]

export const getDaysInMonth = (year, month) => new Date(year, month, 0).getDate();

const MonthDropdown = ({ month: monthArg, year: yearArg, monthCallback, yearCallback }) => {
  const [month, setMonth] = useState(monthArg)
  const [year, setYear] = useState(yearArg)
  const currentMonth = new Date().getMonth()
  
  const setPreviousYear = val => {
    setYear(year - 1)
    yearCallback(year - 1)
  }

  const setNextYear = val => {
    setYear(year + 1)
    yearCallback(year + 1)
  }

  const getStyles = (item) => {    
    if (currentMonth === months.indexOf(item)) {
      return { backgroundColor: '#eee' }
    } else if (months.indexOf(item) === monthArg) {
      return { backgroundColor: '#37828b', color: 'white' }
    } else {
      return {}
    }
  }
  
  return (
    <>
      <Menu as="div" className="relative inline-block text-left">
        <div>
          <Menu.Button className="flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
            {`${months[month]} ${year}`}
            <CalendarIcon
              className="-mr-1 ml-2 h-5 w-5 text-violet-200 "
              aria-hidden="true"
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black/5">
            <div className="px-1 py-1 ">

              <div className="relative flex items-center rounded-md bg-white shadow-sm md:items-stretch" style={{ justifyContent: 'space-between' }}>
                <button type="button" className="flex h-9 w-12 items-center justify-center rounded-l-md border-y border-l border-gray-300 pr-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pr-0 md:hover:bg-gray-50" onClick={setPreviousYear}>
                  <span className="sr-only">Previous</span>
                  <svg className="size-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                    <path fill-rule="evenodd" d="M11.78 5.22a.75.75 0 0 1 0 1.06L8.06 10l3.72 3.72a.75.75 0 1 1-1.06 1.06l-4.25-4.25a.75.75 0 0 1 0-1.06l4.25-4.25a.75.75 0 0 1 1.06 0Z" clip-rule="evenodd" />
                  </svg>
                </button>
                <button type="button" className="hidden border-y border-gray-300 px-3.5 text-sm font-semibold text-gray-900 hover:bg-gray-50 focus:relative md:block">{year}</button>
                <span className="relative -mx-px h-5 w-px bg-gray-300 md:hidden"></span>
                <button type="button" className="flex h-9 w-12 items-center justify-center rounded-r-md border-y border-r border-gray-300 pl-1 text-gray-400 hover:text-gray-500 focus:relative md:w-9 md:pl-0 md:hover:bg-gray-50" onClick={setNextYear}>
                  <span className="sr-only">Next</span>
                  <svg className="size-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
                    <path fill-rule="evenodd" d="M8.22 5.22a.75.75 0 0 1 1.06 0l4.25 4.25a.75.75 0 0 1 0 1.06l-4.25 4.25a.75.75 0 0 1-1.06-1.06L11.94 10 8.22 6.28a.75.75 0 0 1 0-1.06Z" clip-rule="evenodd" />
                  </svg>
                </button>
              </div>

              <div className="grid grid-cols-1 gap-1 sm:grid-cols-3">
                {months.map(item => ( 
                  <Menu.Item>
                    {(options) => {
                      return (
                        <button
                          className={`text-gray-900 group flex items-center rounded-md px-2 py-2 text-sm`}
                          style={getStyles(item)}
                          onClick={(e) => {
                            const val = months.indexOf(e.target.textContent)                          
                            setMonth(val)
                            monthCallback(val)
                          }}
                        >
                          {item}
                        </button>
                      )            
                    }}
                  </Menu.Item>
                ))}
            </div>
           </div>
         </Menu.Items>
       </Transition>
     </Menu>      
    </>
  )
}

export default MonthDropdown